export const duration = [
  {
    label: "Daily",
    value: 10,
  },
  {
    label: "Weekly",
    value: 30,
  },
  {
    label: "Monthly",
    value: 20,
  },
  {
    label: "Quarterly",
    value: 60,
  },
  {
    label: "Half Year",
    value: 50,
  },
];

export const inventory_keys = [
  {
    label: "Gross Amount",
    value: 10,
  },
  {
    label: "PCS",
    value: 15,
  },
  {
    label: "Order",
    value: 11,
  },
  {
    label: "PCS / Order / Min Gross Amount",
    value: 21,
  },
  {
    label: "PCS / SKU",
    value: 25,
  },
  {
    label: "PCS / SKU / Order",
    value: 30,
  },
];

export const lifecycle_status = [
  {
    label: "Launched",
    value: 10,
  },
  {
    label: "Trial",
    value: 20,
  },
];

const MAP = {
  key_type: inventory_keys,
  duration: duration,
  lifecycle_statu: lifecycle_status,
};

export function getOptionsLabel(row, column) {
  const options = MAP[column.property];
  if (options !== undefined && options !== null) {
    const cur_value = row[column.property];
    const cur_option = options.find((item) => {
      return item.value == cur_value;
    });
    return cur_option !== undefined ? cur_option.label : "";
  }
  return "";
}
