
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { hideModal, modalHideListener } from "@/core/helpers/dom";
import { ApiMerchant, ApiBrands } from "@/core/api";
import mixin from "@/mixins";
import _ from "lodash";
import {
  duration,
  inventory_keys,
} from "@/views/merchants/InventoryRuleDataSource";

interface Brands {
  id: number;
  logo: string;
  name: string;
  status: number;
  description: string;
}
interface propData {
  checkStrictly: boolean;
  value: string;
  label: string;
  children: string;
  lazy: boolean;
  lazyLoad: any;
}

export default defineComponent({
  name: "add-inventory-rule-modal",
  props: {
    id: { type: Number, default: 0 },
    merchantId: { type: Number, default: 0 },
    tableData: {
      type: Array,
      required: true,
    },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const tableArr = ref(props.tableData);
    const formRef = ref<null | HTMLFormElement>(null);
    const addInventoryRuleModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);
    const brandLoading = ref<boolean>(false);
    const chanelOptions = ref([]);
    const brands = ref<Array<Brands>>([]);
    const propData = ref<propData>({
      checkStrictly: true,
      value: "id",
      label: "name",
      children: "category_items",
      lazy: false,
      lazyLoad(node, resolve) {
        resolve(node.children);
      },
    });
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      id: props.id,
      channel_id: "",
      brand_id: "",
      category_id: "",
      key_type: "",
      key_value: "",
      duration: "",
    });
    const options = ref({
      duration,
      inventory_keys,
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          getFormData(newVal);
        } else {
          resetForm();
        }
      }
    );

    const rules = ref({
      brand_id: [
        {
          required: false,
          message: "Brand is required",
          trigger: "change",
        },
      ],
      category_id: [
        {
          required: false,
          message: "Category is required",
          trigger: "change",
        },
      ],
      key_type: [
        {
          required: true,
          message: "Key is required",
          trigger: "change",
        },
      ],
      key_value: [
        {
          required: true,
          message: "Value is required",
          trigger: "change",
        },
      ],
      duration: [
        {
          required: false,
          message: "Duration is required",
          trigger: "change",
        },
      ],
    });

    const parentChange = (value) => {
      if (value) {
        formData.value.category_id = value[value.length - 1];
      } else {
        formData.value.category_id = "";
      }
    };

    const keyTypeChange = (value) => {
      if (!isShowDuration.value) {
        formData.value.duration = "";
      }
    };

    const isShowDuration = computed(() => {
      let flag = false;
      const key_type = Number(formData.value.key_type);
      switch (key_type) {
        case 11:
        case 10:
        case 15:
        case 25:
          flag = true;
          break;
      }
      return flag;
    });

    const getCompanyChanelList = () => {
      return ApiMerchant.channelDataSource({
        merchant_id: props.merchantId,
        status: 10,
        max_item: "all",
      })
        .then(({ data }) => {
          if (data.code == 0) {
            chanelOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const getBrandsList = (search?: string, id?: number) => {
      brandLoading.value = true;
      ApiBrands.getBrandSourceData({
        search_value: search,
        id: id,
      })
        .then(({ data }) => {
          brandLoading.value = false;
          if (data.code == 0) {
            brands.value.splice(0, brands.value.length, ...data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const debounceBrandSearch = _.debounce(getBrandsList, 1000);
    const searchBrandItems = (query) => {
      debounceBrandSearch(query);
    };
    const getFormData = (id) => {
      if (id) {
        loading.value = true;
        ApiMerchant.getMerchantPimInventoryRuleData({ id: id })
          .then(({ data }) => {
            loading.value = false;
            if (data.code == 0) {
              Object.keys(formData.value).forEach((key) => {
                formData.value[key] = data.data[key];
              });
              getBrandsList("", data.data.brand_id);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (props.id) {
            editKeyarameters();
          } else {
            addKeyarameters();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addKeyarameters = () => {
      ApiMerchant.addMerchantPimInventoryRule(
        Object.assign({ merchant_id: props.merchantId }, formData.value)
      )
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addInventoryRuleModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const editKeyarameters = () => {
      ApiMerchant.updateInventoryRule(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addInventoryRuleModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      hideModal(addInventoryRuleModalRef.value);
      resetForm();
      emit("reset-form");
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      getCompanyChanelList();
      modalHideListener(addInventoryRuleModalRef.value, () => {
        emit("reset-form");
      });
    });

    return {
      loading,
      brandLoading,
      chanelOptions,
      brands,
      tableArr,
      propData,
      formData,
      options,
      rules,
      formRef,
      addInventoryRuleModalRef,
      submitButton,
      parentChange,
      keyTypeChange,
      isShowDuration,
      searchBrandItems,
      getFormData,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
