
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MBTaggings } from "magicbean-saas-common";
import { ApiBase, ApiMerchant, ApiBrands, ApiCategories } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import _ from "lodash";
import AddInventoryRuleModal from "@/views/merchants/AddInventoryRuleModal.vue";
import {
  inventory_keys,
  duration,
  getOptionsLabel,
} from "@/views/merchants/InventoryRuleDataSource";
import { setModuleBCN } from "@/core/directive/function/common";
import { useI18n } from "vue-i18n";

interface TaggingValue {
  short_key: string;
  items: Array<TaggingValueItem>;
}

interface TaggingValueItem {
  short_key: string;
  value: string | Array<string>;
}

interface Brands {
  id: number;
  logo: string;
  name: string;
  status: number;
  description: string;
}
interface Categories {
  id: number;
  name: string;
  code: number;
  status: number;
  category_items: Array<any>;
  parent_id: number;
}
interface Props {
  checkStrictly: boolean;
  emitPath: boolean;
  multiple: boolean;
  value: string;
  label: string;
  children: string;
}
export default defineComponent({
  name: "merchants-pim",
  components: {
    MBTaggings,
    AddInventoryRuleModal,
  },
  setup() {
    const { t } = useI18n();

    const loading = ref<boolean>(false);
    const route = useRoute();
    const router = useRouter();
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const pimTaggingsRef = ref<null | HTMLFormElement>(null);
    const detail = ref({});
    const propsData = ref<Props>({
      checkStrictly: false,
      emitPath: false,
      multiple: true,
      value: "id",
      label: "name",
      children: "category_items",
    });
    const formData = ref({
      merchant_id: route.params.id,
      selected_brands: [],
      selected_categories: [],
      selected_target_groups: [],
      service_status: 0,
      product_identifier: "",
      basic_data: "",
      categories: "",
      digital_media_assets: "",
      content_marketing: "",
      pricing: "",
      inventory: "",
      data_quality: "",
      packaging: "",
    });
    const typeN = ref(10);
    const brands = ref<Array<Brands>>([]);
    const brandLoading = ref<boolean>(false);
    const selectData = ref<Array<Categories>>([]);
    const categories = ref<Array<Categories>>([]);
    const categorieLoading = ref<boolean>(false);
    const target_groups = ref<Array<Categories>>([]);
    const target_groupLoading = ref<boolean>(false);
    const basic_segmentationSource = ref([]);
    const basic_segmentationValues = ref([]);
    const brand_coverageSource = ref([]);
    const brand_coverageValues = ref([]);
    const product_dynamicSource = ref([]);
    const product_dynamicValues = ref([]);
    const inventory_capabilitySource = ref([]);
    const inventory_capabilityValues = ref([]);
    const price_SystemSource = ref([]);
    const price_SystemValues = ref([]);
    const online_pim_DataConfig = ref({});
    const online_pim_DataSource = ref([]);
    const online_pim_DataValues = ref([]);
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const options = ref({
      duration,
      inventory_keys,
      inventoryRuleLoading: true,
      inventoryRuleList: [],
      inventoryRuleCurrentPage: 1,
      inventoryRuleTotal: 0,
      inventoryRuleTotalPage: 0,
      inventoryRulePageSize: 10,
    });

    const short_key = [
      "merchant_pim_spec_basic_segmentation",
      "merchant_pim_spec_brand_coverage",
      "merchant_pim_spec_product_dynamic",
      "merchant_pim_spec_inventory_capability",
      "merchant_pim_spec_price_system",
      "merchant_pim_spec_online_pim_data",
    ];

    const basicSegmentation = ref<null | HTMLFormElement>(null);
    const brandCoverage = ref<null | HTMLFormElement>(null);
    const productDynamic = ref<null | HTMLFormElement>(null);
    const inventoryCapabilaity = ref<null | HTMLFormElement>(null);
    const priceSystem = ref<null | HTMLFormElement>(null);
    const onlinePimData = ref<null | HTMLFormElement>(null);

    const getTaggingDataSource = () => {
      return ApiBase.getTaggingGroupData({ short_key: short_key })
        .then(({ data }) => {
          if (data.code == 0) {
            basic_segmentationSource.value =
              data.data.merchant_pim_spec_basic_segmentation.items;
            brand_coverageSource.value =
              data.data.merchant_pim_spec_brand_coverage.items;
            product_dynamicSource.value =
              data.data.merchant_pim_spec_product_dynamic.items;
            inventory_capabilitySource.value =
              data.data.merchant_pim_spec_inventory_capability.items;
            price_SystemSource.value =
              data.data.merchant_pim_spec_price_system.items;
            online_pim_DataSource.value =
              data.data.merchant_pim_spec_online_pim_data.items;
            online_pim_DataConfig.value =
              data.data.merchant_pim_spec_online_pim_data.options;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getTaggingDataValues = () => {
      return ApiMerchant.getMerchantPimData({ merchant_id: route.params.id })
        .then(({ data }) => {
          if (data.code == 0) {
            Object.keys(formData.value).forEach((key) => {
              formData.value[key] = data.data[key];
            });

            basic_segmentationValues.value =
              data.data.tagging_data.merchant_pim_spec_basic_segmentation
                ?.items || [];
            brand_coverageValues.value =
              data.data.tagging_data.merchant_pim_spec_brand_coverage?.items ||
              [];
            product_dynamicValues.value =
              data.data.tagging_data.merchant_pim_spec_product_dynamic?.items ||
              [];
            inventory_capabilityValues.value =
              data.data.tagging_data.merchant_pim_spec_inventory_capability
                ?.items || [];
            price_SystemValues.value =
              data.data.tagging_data.merchant_pim_spec_price_system?.items ||
              [];
            online_pim_DataValues.value =
              data.data.tagging_data.merchant_pim_spec_online_pim_data?.items ||
              [];

            detail.value = data.data;
            getBrandsList("", data.data.selected_brands);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const getInventoryRuleList = async () => {
      options.value.inventoryRuleLoading = true;
      const { data } = await ApiMerchant.getMerchantPimInventoryRuleList({
        merchant_id: route.params.id,
        page: options.value.inventoryRuleCurrentPage,
        page_size: options.value.inventoryRulePageSize,
      });
      options.value.inventoryRuleLoading = false;
      if (data.code == 0) {
        options.value.inventoryRuleList = data.data.items;
        options.value.inventoryRuleTotal = data.data.total;
        options.value.inventoryRuleTotalPage = data.data.total_page;
      }
    };

    const getUpdateInventoryRuleList = () => {
      options.value.inventoryRuleCurrentPage = 1;
      getInventoryRuleList();
    };

    const debounceSearchInventoryRuleList = _.debounce(
      getUpdateInventoryRuleList,
      1000
    );

    const searchItemsInventoryRuleList = () => {
      debounceSearchInventoryRuleList();
    };

    const setInventoryRulePerPage = (event) => {
      options.value.inventoryRulePageSize = parseInt(event.target.value);
      getInventoryRuleList();
    };

    const handleInventoryRuleCurrentChange = (val: number) => {
      options.value.inventoryRuleCurrentPage = val;
      getInventoryRuleList();
    };

    const rowsInventoryRuleStartNumber = computed(() => {
      return options.value.inventoryRuleTotal === 0
        ? 0
        : (options.value.inventoryRuleCurrentPage - 1) *
            options.value.inventoryRulePageSize +
            1;
    });

    const rowsInventoryRuleEndNumber = computed(() => {
      return options.value.inventoryRuleCurrentPage *
        options.value.inventoryRulePageSize >=
        options.value.inventoryRuleTotal
        ? options.value.inventoryRuleTotal
        : options.value.inventoryRuleCurrentPage *
            options.value.inventoryRulePageSize;
    });
    const ruleId = ref(0);
    const handleAddRule = () => {
      ruleId.value = 0;
    };
    const handleEditRule = (index, row) => {
      ruleId.value = row.id;
    };
    const handleDeleteRule = (index, row) => {
      Swal.fire({
        text: "Are you sure you would like to delete it?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiMerchant.deleteInventoryRule({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: "Deleted successfully!",
              icon: "success",
              buttonsStyling: false,
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              getUpdateInventoryRuleList();
            });
          }
        }
      });
    };

    const getBrandsList = (search?: string, id?: Array<any>) => {
      brandLoading.value = true;
      ApiBrands.getBrandSourceData({
        search_value: search,
        id: id,
      })
        .then(({ data }) => {
          brandLoading.value = false;
          if (data.code == 0) {
            brands.value.splice(0, brands.value.length, ...data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    const debounceBrandSearch = _.debounce(getBrandsList, 1000);
    const searchBrandItems = (query) => {
      debounceBrandSearch(query);
    };
    const getCategoriesList = (type: number, search?: string) => {
      if (type == 1) {
        target_groupLoading.value = true;
      } else if (type == 0) {
        categorieLoading.value = true;
      }
      ApiCategories.getCategorySourceData({
        code: search,
      })
        .then(({ data }) => {
          if (type == 1) {
            target_groupLoading.value = false;
          } else if (type == 0) {
            categorieLoading.value = false;
          }
          if (data.code == 0) {
            if (type == 1) {
              target_groups.value.splice(
                0,
                target_groups.value.length,
                ...data.data
              );
            } else if (type == 0) {
              categories.value.splice(0, categories.value.length, ...data.data);
            } else {
              selectData.value.splice(0, selectData.value.length, ...data.data);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          // let taggingData = pimTaggingsRef.value?.formData;
          let taggingArr: Array<TaggingValue> = [];

          let basicData = basicSegmentation.value?.formData;
          let basicitems: Array<TaggingValueItem> = [];
          for (let item in basicData) {
            basicitems.push({
              short_key: item,
              value: basicData[item],
            });
          }
          let basicObj: TaggingValue = {
            short_key: "merchant_pim_spec_basic_segmentation",
            items: basicitems,
          };
          taggingArr.push(basicObj);

          let brandData = brandCoverage.value?.formData;
          let branditems: Array<TaggingValueItem> = [];
          for (let item in brandData) {
            branditems.push({
              short_key: item,
              value: brandData[item],
            });
          }
          let brandObj: TaggingValue = {
            short_key: "merchant_pim_spec_brand_coverage",
            items: branditems,
          };
          taggingArr.push(brandObj);

          let inventoryData = inventoryCapabilaity.value?.formData;
          let inventoryitems: Array<TaggingValueItem> = [];
          for (let item in inventoryData) {
            inventoryitems.push({
              short_key: item,
              value: inventoryData[item],
            });
          }
          let inventoryObj: TaggingValue = {
            short_key: "merchant_pim_spec_inventory_capability",
            items: inventoryitems,
          };
          taggingArr.push(inventoryObj);

          let productData = productDynamic.value?.formData;
          let productitems: Array<TaggingValueItem> = [];
          for (let item in productData) {
            productitems.push({
              short_key: item,
              value: productData[item],
            });
          }
          let productObj: TaggingValue = {
            short_key: "merchant_pim_spec_product_dynamic",
            items: productitems,
          };
          taggingArr.push(productObj);

          let priceData = priceSystem.value?.formData;
          let priceitems: Array<TaggingValueItem> = [];
          for (let item in priceData) {
            priceitems.push({
              short_key: item,
              value: priceData[item],
            });
          }
          let priceObj: TaggingValue = {
            short_key: "merchant_pim_spec_price_system",
            items: priceitems,
          };
          taggingArr.push(priceObj);

          let onlineData = onlinePimData.value?.formData;
          let onlineitems: Array<TaggingValueItem> = [];
          for (let item in onlineData) {
            onlineitems.push({
              short_key: item,
              value: onlineData[item],
            });
          }
          let onlineObj: TaggingValue = {
            short_key: "merchant_pim_spec_online_pim_data",
            items: onlineitems,
          };
          taggingArr.push(onlineObj);

          loading.value = true;
          if (submitButton.value) {
            // submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiMerchant.updateMerchantPimData({
            ...formData.value,
            tagging_data: taggingArr,
          })
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const backToList = () => {
      if (route.path.indexOf("offline-merchants") != -1) {
        router.push("/merchant-network/offline-merchants");
      } else {
        router.push("/merchants");
      }
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      loading.value = true;
      Promise.all([
        getTaggingDataSource(),
        getTaggingDataValues(),
        getInventoryRuleList(),
        getCategoriesList(0, "Categories"),
        getCategoriesList(1, "crowd"),
        getCategoriesList(2),
      ])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    });

    return {
      loading,
      ruleId,
      typeN,
      formRef,
      submitButton,
      options,
      pimTaggingsRef,
      formData,
      detail,
      brands,
      propsData,
      selectData,
      categories,
      target_groups,
      basic_segmentationSource,
      basic_segmentationValues,
      brand_coverageSource,
      brand_coverageValues,
      product_dynamicSource,
      product_dynamicValues,
      inventory_capabilitySource,
      inventory_capabilityValues,
      price_SystemSource,
      price_SystemValues,
      online_pim_DataConfig,
      online_pim_DataSource,
      online_pim_DataValues,
      brandLoading,
      categorieLoading,
      target_groupLoading,
      handleAddRule,
      handleEditRule,
      handleDeleteRule,
      searchBrandItems,
      submit,
      backToList,
      basicSegmentation,
      brandCoverage,
      productDynamic,
      inventoryCapabilaity,
      priceSystem,
      onlinePimData,
      getOptionsLabel,
      getUpdateInventoryRuleList,
      setInventoryRulePerPage,
      handleInventoryRuleCurrentChange,
      rowsInventoryRuleStartNumber,
      rowsInventoryRuleEndNumber,
    };
  },
});
